<template>
  <v-tabs
    grow
    show-arrows
    background-color="grey lighten-2"
  >
    <v-tab>Ergebnisse</v-tab>
    <v-tab v-if="!$store.getters.isStbMWkDiscipline(id)">Turner tauschen</v-tab>
    <v-tab v-if="!$store.getters.isStbMWkDiscipline(id)">Penalty</v-tab>
    <v-tab v-if="!$store.getters.isStbMWkDiscipline(id)">Zwischenstand</v-tab>
    <v-tab v-if="!$store.getters.isStbMWkDiscipline(id)">Kontrollansicht</v-tab>

    <v-tab-item>
      <v-tabs
        grow
        show-arrows
        center-active
        v-model="geraet"
        background-color="red lighten-5"
      >
        <template v-for="d in disciplines">
          <v-tab
            v-if="isWkDiscipline(id, d._id)"
            :key="d._id"
          >
            {{ d.order }}. {{ d.name }}
          </v-tab>
        </template>
      </v-tabs>

      <v-tabs-items v-model="geraet" touchless>
        <v-tab-item
          v-for="d in disciplines"
          :key="d._id"
        >
          <geraet :id="id" :df="df" :r="r" :e="e" :did="d._id" :f="f" />
        </v-tab-item>
      </v-tabs-items>
    </v-tab-item>
    <v-tab-item v-if="!$store.getters.isStbMWkDiscipline(id)"><tausch /></v-tab-item>
    <v-tab-item v-if="!$store.getters.isStbMWkDiscipline(id)"><penalty /></v-tab-item>
    <v-tab-item v-if="!$store.getters.isStbMWkDiscipline(id)">
      <zwischenstand :id="id" :df="df" :r="r" :e="e" />
    </v-tab-item>
    <v-tab-item>
      <v-tabs
        grow
        show-arrows
        center-active
        v-model="geraetk"
        background-color="red lighten-5"
      >
        <template v-for="d in disciplines">
          <v-tab
            v-if="isWkDiscipline(id, d._id)"
            :key="d._id"
          >
            {{ d.order }}. {{ d.name }}
          </v-tab>
        </template>
      </v-tabs>

      <v-tabs-items v-model="geraetk" touchless>
        <v-tab-item
          v-for="d in disciplines"
          :key="d._id"
        >
          <geraetk :id="id" :df="df" :r="r" :e="e" :did="d._id" :f="f" />
        </v-tab-item>
      </v-tabs-items>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { useAuth } from '@/plugins/auth'

export default {
  name: 'bei',

  components: {
    Geraet: () => import('./bei/geraet'),
    Geraetk: () => import('./bei/geraetk'),
    Tausch: () => import('./bei/tausch'),
    Penalty: () => import('./bei/penalty'),
    Zwischenstand: () => import('./bei/zwischenstand')
  },

  setup (props, context) {
    return {
      ...useAuth(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    geraet: 0,
    geraetk: 0
  }),

  computed: {
    disciplines () {
      return this.e?.disciplines?.slice().sort((a, b) => a.order < b.order ? -1 : 1).map(d => ({
        ...this.df.find(df => df._id === d._discipline),
        order: d.order
      })).filter(d => d.regular)
    }
  }
}
</script>

<style scoped>

</style>
